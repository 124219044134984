import { FC } from 'react'
import classnames from 'classnames'

import { Flex, GlowHeading, Link } from '~ui'

import styles from './ErrorPage.module.scss'

type ErrorPageProps = {
  type: 'error' | 'not-found'
}

const MESSAGE = {
  'not-found': 'Page Not Found',
  error: 'Site is under maintenance',
}

const ErrorPage: FC<ErrorPageProps> = ({ type }) => (
  <Flex
    className={classnames(styles['error-page'], styles[type])}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <GlowHeading Tag="h2" size="h1" color="pink" center>
      {MESSAGE[type]}
    </GlowHeading>
    <Link href="/" color="green">
      Go home
    </Link>
  </Flex>
)

export default ErrorPage
