import { NextPage } from 'next'

import ErrorPage from 'components/error-page'
import { MetaTags } from '~shared'
import { Layout } from '~ui'

const NotFound: NextPage = () => (
  <Layout>
    <MetaTags title="Not found" />
    <Layout.Header />
    <ErrorPage type="not-found" />
    <Layout.Footer />
  </Layout>
)

export default NotFound
